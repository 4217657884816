import React, { useState } from "react";
import {
  ActivityCommented,
  ActivityFulfilled,
  ActivityLiked,
  AllActivity,
} from "./index";
import "../../../styles/ProfileActivity.css";
import { useFetchActivities } from "src/hooks/useActivity";
import MissingData from "../MissingData";
import { MISSING_DATA_ENUM } from "src/consts/missingDataEnum";
import { ActivityPageProps } from "src/types/profile-props";
import { shareTo } from "src/utils/share";

const tabComponents = {
  all: AllActivity,
  commented: ActivityCommented,
  fulfilled: ActivityFulfilled,
  liked: ActivityLiked,
};

const ActivityPage = ({ user, isFulfilled }: ActivityPageProps) => {
  const [activeTab, setActiveTab] = useState("all");
  const { activities } = useFetchActivities(user, "ALL");

  const CurrentTabContent = tabComponents[activeTab] || AllActivity;

  return (
    <>
      {activities.length > 0 ? (
        <div className="activity-page-container">
          <div className="activity-page-menu-box">
            {Object.keys(tabComponents).map((tab) => (
              <div
                key={tab}
                className={`${
                  activeTab === tab
                    ? "activity-page-menu-active-item"
                    : "activity-page-menu-item"
                }`}
                onClick={() => setActiveTab(tab)}
                aria-pressed={activeTab === tab}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </div>
            ))}
          </div>
          <div className="activity-content">
            <CurrentTabContent
              shareTo={shareTo}
              user={user}
              isFulfilled={isFulfilled}
            />
          </div>
        </div>
      ) : (
        <MissingData
          type={
            user?.role !== "ANGEL"
              ? MISSING_DATA_ENUM.USER_ACTIVITY
              : MISSING_DATA_ENUM.ANGEL_ACIVITY
          }
        ></MissingData>
      )}
    </>
  );
};

export default ActivityPage;
