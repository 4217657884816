import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "../../styles/Dashboard.css";
import "../../styles/comments-line.css";
import DefaultImage from "../../assets/images/defaultUser.png";
import {
  createCharitiesComments,
  createComments,
  createDreamAngelComments,
} from "../../services/api";
import EmojiMenu from "../Partials/EmojiMenu";
import { CommentProps } from "src/types/comments-props";
import Reply from "./Reply";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import TooltipDetails from "../Tooltip/TooltipDetails";
import { useSelector, useDispatch } from "react-redux";
import { CommentsReply, UsersModule } from "src/types/redux";
import { setActiveReply } from "src/store/action";

const Comment = ({
  elem,
  dreamId,
  user,
  autoFocus = true,
  type,
  publicShared = false,
  newsFeedId,
  addLines = false,
  isFulfilled,
  textFormatted,
}: CommentProps) => {
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;

  const commentReplyId = useSelector(
    (state: CommentsReply) => state.comments.commentsReply.parentReplyId
  );

  const tooltipKey = generateTooltipKey(elem?.user?.id);
  const profileId = localStorage.getItem("profileId");

  const [userReply, setUserReply] = useState<any>([]);
  const [seeAllComments, setSeeAllComments] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [text, setText] = useState<string>("");
  const [emojiPicker, showEmojiPicker] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setUserReply(elem.children);
  }, [elem, elem.children]);

  const handleReply = (e, dreamId, replyUserId, parentId) => {
    if (text.length) {
      setText(e.target.value);

      const requestData = {
        message: text,
        charityId: type === "charity" ? dreamId : undefined,
        dreamId: type === "dream" ? dreamId : undefined,
        dreamAngelId: type === "angel" ? dreamId : undefined,
        replyUserId: replyUserId,
        parentId: parentId,
      };

      let request;

      switch (type) {
        case "charity":
          request = createCharitiesComments(requestData);
          break;
        case "dream":
          request = createComments(requestData);
          break;
        case "angel":
          request = createDreamAngelComments(
            Object.assign(requestData, { newsFeedId: newsFeedId })
          );
          break;
        default:
          return;
      }

      request.then((res) => {
        if (res?.data?.parentId === elem?.id) {
          updateComments(res?.data, userReply, setUserReply, index);
        } else {
          updateReplies(res?.data, userReply, setUserReply);
        }
      });

      setText("");
      dispatch(setActiveReply(""));
    }
  };

  const updateComments = (data, prevData, setData, i) => {
    let newArray = [...prevData];
    index ? newArray.splice(i, 0, data) : newArray.splice(0, 0, data);

    setData(newArray);
  };

  const updateReplies = (data, prevData, setData) => {
    const findAndUpdate = (nodes) => {
      return nodes.map((el) => {
        if (data?.parentId === el?.id) {
          return {
            ...el,
            children: el?.children ? [...el?.children, data] : [data],
          };
        } else if (el?.children) {
          return {
            ...el,
            children: findAndUpdate(el.children),
          };
        } else {
          return el;
        }
      });
    };

    const updatedReply = findAndUpdate(prevData);
    setData(updatedReply);
  };

  const onKeyEnter = (e, dreamId, replyUserId, parentId) => {
    if (!text.length && e.key === "Enter") {
      e.preventDefault();
    } else if (e.key === "Enter" && text.length) {
      e.preventDefault();
      handleReply(e, dreamId, replyUserId, parentId);
    }
  };

  const allReply = !publicShared
    ? userReply
        ?.map((el, i) => {
          const lastElement =
            userReply?.length > 3
              ? !seeAllComments
                ? userReply?.length - 3
                : 0
              : 0;
          return (
            <div className="reply-line-container" key={el?.id}>
              <div className="second-line-container">
                <div className="reply-line">
                  {lastElement !== i ? <div className="small-line"></div> : ""}
                  {lastElement !== i ? (
                    <div className="rounded-line"></div>
                  ) : (
                    <div
                      className="rounded-line"
                      style={{ marginLeft: "19.5px" }}
                    ></div>
                  )}
                </div>
                {lastElement !== i ? (
                  <div className="continued-line"></div>
                ) : (
                  ""
                )}
              </div>
              <Reply
                dreamId={dreamId}
                reply={el}
                parentComment={elem}
                user={user}
                onKeyEnter={onKeyEnter}
                handleReply={handleReply}
                text={text}
                setText={setText}
                isFulfilled={isFulfilled}
              />
            </div>
          );
        })
        .reverse()
    : [];

  return (
    <div
      id={`currentComment-${elem?.id}`}
      className="current-comment"
      key={elem?.id}
    >
      <div className="first-comment">
        <div className="avatar-container">
          <div
            className={`avatar-image  ${
              elem?.user?.role === "ANGEL" ? "gradient-border" : ""
            }`}
          >
            <Link to={`/dashboard/public-page/${elem?.user?.id}`}>
              <img
                src={elem?.user?.mainImageUrl || DefaultImage || ""}
                alt="userimg"
                className="avatar-35 rounded-circle"
                style={{ objectFit: "cover", cursor: "pointer" }}
                id="elem1"
              />
            </Link>
          </div>
          {userReply?.length ? (
            <div
              className={`avatar-line  ${
                elem?.user?.role === "ANGEL" ? "avatar-line-angel" : ""
              }`}
            ></div>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex flex-column " style={{ width: "100%" }}>
          <div className="d-flex" style={{ marginBottom: "5px" }}>
            <div className="d-flex flex-column comment-css">
              <div className="d-flex flex-row">
                <Link
                  to={`/dashboard/public-page/${elem?.user?.id}`}
                  data-tooltip-id={tooltipKey}
                  className="comment-link-text"
                >
                  <span className="user-name comment-user-name-text">
                    {elem?.user?.firstName || "No Name"}{" "}
                    {elem?.user?.lastName || ""}
                  </span>
                </Link>
                {elem?.user?.role === "ANGEL" ? (
                  <div className="tooltip-angel-badge comment-badge">
                    Dream Angel
                  </div>
                ) : (
                  ""
                )}
              </div>

              <span
                style={{
                  hyphens: "auto",
                  wordBreak: "break-word",
                }}
                className={`comment-message text-wrap ${textFormatted ? "message-formatted" : ""}`}
              >
                {elem.message}
              </span>
            </div>
            {profileId ? (
              <TooltipDetails
                userId={elem?.user?.id}
                isFulfilled={isFulfilled}
                allFulfilled={allFulfilled}
                tooltipKey={tooltipKey}
              />
            ) : (
              <></>
            )}
          </div>
          <div
            className="d-flex align-items-start gap-2"
            style={{ marginLeft: "5px" }}
          >
            <span className="comment-create-time cursor-pointer">
              {moment(elem?.createdAt).fromNow(true)} ago
            </span>
            <div className="reply-hover-second">
              <span
                className="comment-reply cursor-pointer"
                onClick={() => {
                  if (!publicShared && commentReplyId !== elem?.id) {
                    setText("");
                    dispatch(setActiveReply(elem?.id));
                  } else {
                    dispatch(setActiveReply(""));
                  }
                }}
              >
                Reply
              </span>
            </div>

            {allReply?.length > 3 && !publicShared ? (
              <div
                className="d-flex"
                style={{ paddingLeft: "8px", borderBottom: "2px" }}
              >
                <span
                  onClick={() => setSeeAllComments((prev) => !prev)}
                  className="comment-reply see-all-comments"
                >
                  {!seeAllComments ? "View All" : "Hide All"} ({allReply.length}
                  )
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className="current-replies">
        <div className="replies-container">
          <div className="reply-input">
            {commentReplyId === elem?.id && (
              <div className="d-flex w-100 mb-2 mt-2">
                <div className="d-flex align-items-center w-100 gap-2">
                  <img
                    src={user?.mainImageUrl || DefaultImage || ""}
                    alt="userimg"
                    className="avatar-35 rounded-circle"
                    style={{ objectFit: "cover", cursor: "pointer" }}
                  />

                  <div className="comment-reply-container position-relative">
                    <div className="reply-name">
                      <span>
                        {elem?.user?.firstName || "No name"}{" "}
                        {elem?.user?.lastName || ""}
                      </span>
                    </div>
                    <input
                      className="d-flex flex-nowrap m-1 reply-input"
                      style={{ border: "0", paddingRight: "60px" }}
                      autoFocus={autoFocus}
                      onKeyDown={(e) =>
                        onKeyEnter(e, dreamId, elem?.user?.id, elem?.id)
                      }
                      onChange={(e) => setText(e.target.value)}
                      value={text}
                    />
                    <EmojiMenu
                      reply={true}
                      showEmojiPicker={showEmojiPicker}
                      emojiPicker={emojiPicker}
                      setText={setText}
                      sendMessage={(e) =>
                        handleReply(e, dreamId, elem?.user?.id, elem?.id)
                      }
                      currentUserComment={text}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {!seeAllComments && allReply?.length && !publicShared ? (
            <div className="">{allReply.splice(0, 3)}</div>
          ) : (
            ""
          )}

          {userReply?.length && seeAllComments && !publicShared ? (
            <div className="">{allReply}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Comment;
