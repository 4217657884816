import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fulfill,
  getAllProfileDonations,
  getDreamDonations,
  getPublicDreamDonations,
  getUser,
} from "../../services/api";
import {
  setAddProfileReceived,
  setAddPublicReceived,
  setAllProfileFulfilled,
  setProfileReceived,
  setPublicReceived,
  setUser,
} from "../../store/users";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PricingModal from "../../pages/Pricing/PricingModal";
import InfiniteScroll from "react-infinite-scroller";
import loaderGif from "../../assets/images/page-img/page-load-loader.gif";
import { UsersModule } from "src/types/redux";
import { ProfileReceivedProps } from "src/types/profile-props";
import MissingData from "./MissingData";
import { MISSING_DATA_ENUM } from "src/consts/missingDataEnum";
import DreamerReceivedCard from "./Cards/DreamerReceivedCard";
import { fetchUserDreams } from "src/utils";
import AngelReceivedCard from "./Cards/AngelReceivedCard";

let page2d = 1;
let loader = false;

const ProfileReceived = ({ received, dream }: ProfileReceivedProps) => {
  const notify = (message) => toast.success(message);
  const dispatch = useDispatch();
  const params = useParams();
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const mainUser = useSelector((state: UsersModule) => state.usersModule).user;
  const [active, setActive] = useState(true);
  const [customModals, setCustomModal] = useState("");
  const [dreamId, setDreamId] = useState("");
  const [userDreamDetails, setUserDreamDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(received?.meta?.hasNextPage);

  const fetchUsersAndDreams = async () => {
    const usersDet = received.results.map((dr) => dr.user);
    const responses = await fetchUserDreams(usersDet);
    const userDreams = responses
      .map((res) => res.data.results)
      .filter((results) => results.length > 0)
      .flat();
    setUserDreamDetails(userDreams);
  };

  useEffect(() => {
    if (received?.results?.length) {
      fetchUsersAndDreams();
    }
  }, [received]);

  useEffect(() => {
    setHasNextPage(received?.meta?.hasNextPage);
    page2d = received?.meta?.page;
    setCurrentPage(received?.meta?.page);
    loader = false;
  }, [received?.meta?.hasNextPage, received?.meta?.page]);

  useEffect(
    () => () => {
      setCurrentPage(1);
      page2d = 1;
      dispatch(setPublicReceived({}));
      dispatch(setProfileReceived({}));
    },
    []
  );

  const loadMore = () => {
    if (received?.results?.length) {
      if (!loader) {
        page2d += 1;
        loader = true;
        if (params?.id) {
          getPublicDreamDonations(dream.id, page2d).then((response) => {
            dispatch(setAddPublicReceived(response?.data));
            setHasNextPage(response?.data?.meta?.hasNextPage);
            loader = false;
            setCurrentPage(currentPage + 1);
          });
        } else {
          getDreamDonations(dream.id, page2d).then((response) => {
            dispatch(setAddProfileReceived(response?.data));
            setHasNextPage(response?.data?.meta?.hasNextPage);
            loader = false;
            setCurrentPage(currentPage + 1);
          });
        }
      }
    }
  };

  const isFulfilled = (id) => {
    if (!mainUser?.balance) {
      setDreamId(id);
      setCustomModal("priceModal");
    } else {
      fulfill(id).then((res) => {
        if (res) {
          toast.dismiss();
          toast.clearWaitingQueue();
          notify("Success!");
        }
        getUser().then(({ data }) => {
          dispatch(setUser(data));
        });
        getAllProfileDonations().then((res) => {
          dispatch(setAllProfileFulfilled(res?.data));
        });
      });
    }
  };
  const receivedFromDreamer = userDreamDetails ? (
    userDreamDetails?.map((elem, index) => {
      return (
        <Col
          xs={6}
          sm={4}
          lg={3}
          className=" "
          style={{ padding: "0px 5px", marginBottom: "14px" }}
          key={`${elem?.id}-${index}`}
        >
          <DreamerReceivedCard
            isFulfilled={isFulfilled}
            received={elem}
            allFulfilled={allFulfilled}
          />
        </Col>
      );
    })
  ) : (
    <div className="missing-profile-fulfilled-data">
      <MissingData
        type={MISSING_DATA_ENUM.RECEIVED_DONATIONS}
        dreamId={dream.id}
        isFulfilled={isFulfilled}
      ></MissingData>
    </div>
  );

  const receivedFromAngel =
    !received.results ||
    received.results.filter((elem) => elem.user.role === "ANGEL").length <=
      0 ? (
      <div className="missing-profile-fulfilled-data">
        <MissingData
          type={MISSING_DATA_ENUM.RECEIVED_ANGEL_DONATIONS}
          dreamId={dream.id}
          isFulfilled={isFulfilled}
        ></MissingData>
      </div>
    ) : (
      received?.results
        ?.filter(
          (el) => el.user?.id !== mainUser.id && el.user.role === "ANGEL"
        )
        ?.map((elem, index) => {
          return (
            <Col
              xs={6}
              sm={4}
              lg={3}
              className=" "
              style={{ padding: "0px 5px", marginBottom: "14px" }}
              key={`${elem?.id}-${index}`}
            >
              <AngelReceivedCard received={elem} />
            </Col>
          );
        })
    );
  return (
    <>
      <InfiniteScroll
        loadMore={() => loadMore()}
        hasMore={hasNextPage}
        useWindow={true}
      >
        <div className="profile-fulfilled-card d-flex mt-3 mb-3">
          <div
            onClick={() => setActive(true)}
            className={
              active
                ? "profile-fulfilled-button-page profile-fulfilled-active-page-button"
                : "profile-fulfilled-button-page"
            }
          >
            <p className="profile-fulfilled-button-text">Dreamers</p>
          </div>
          <div
            onClick={() => setActive(false)}
            className={
              !active
                ? "profile-fulfilled-button-page profile-fulfilled-active-page-button"
                : "profile-fulfilled-button-page"
            }
          >
            <p className="profile-fulfilled-button-text">Dream Angels</p>
          </div>
        </div>
        <Row style={{ height: "auto", margin: "auto", maxWidth: "1040px" }}>
          <PricingModal
            customModals={customModals}
            setCustomModal={setCustomModal}
            dreamId={dreamId}
          />
          {active ? receivedFromDreamer : receivedFromAngel}
          {loader && (
            <div className="d-flex justify-content-center w-100">
              <img src={loaderGif} alt="loader" style={{ height: "70px" }} />
            </div>
          )}
        </Row>
      </InfiniteScroll>
    </>
  );
};
export default ProfileReceived;
