import React, { useEffect, useRef, useState } from "react";
import useIsMobile from "src/hooks/useIsMobile";
import { debounce } from "lodash";
import {
  fetchDonationMakersInfo,
  processDonationMakersResponses,
} from "src/utils";
import AngelsFulfilledCard from "./AngelsFulfilledCard";
import UsersFulfilledCard from "./UsersFulfilledCard";
import Carousel from "react-multi-carousel";
import { responsive } from "src/consts/breakpoints";
import "../../styles/PublicDreamCard.css";
import { scrollCarousel } from "src/utils/scrollCarousel";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrow-left-icon.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right-icon.svg";
import { SLIDERS_ENUM } from "src/consts/slidersEnum";
import { getAllProfileDonations } from "src/services/api";
import { setAllProfileFulfilled } from "src/store/users";
import { useDispatch } from "react-redux";
import InfiniteViewAllModal from "./InfiniteViewAllModal";

export interface PublicWingMakersCarouselProps {
  users;
  isFulfilled?: (id: string) => void;
}

export default function PublicWingMakersCarousel({
  users,
  isFulfilled,
}: PublicWingMakersCarouselProps) {
  const { id } = useParams();
  const [paddingLeft, setPaddingLeft] = useState(0);
  const profileId = localStorage.getItem("profileId");
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [dreams, setDream] = useState([]);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const isMobile = useIsMobile(768);
  const isCompleted = useIsMobile(860);

  const fetchDreams = async () => {
    const userDetails = await fetchDonationMakersInfo(users);
    const userData = processDonationMakersResponses(userDetails);
    setDream(userData);
  };

  useEffect(() => {
    if (profileId) {
      getAllProfileDonations().then((res) => {
        dispatch(setAllProfileFulfilled(res?.data));
      });
    }
  }, []);

  useEffect(() => {
    fetchDreams();
    const updateMarginLeft = () => {
      const screenWidth = window.innerWidth;
      const calculatedMargin = (screenWidth - 884) / 2;
      const marginInPercentage = (calculatedMargin / screenWidth) * 100;
      setPaddingLeft(marginInPercentage);
    };
    updateMarginLeft();
    window.addEventListener("resize", updateMarginLeft);
    return () => window.removeEventListener("resize", updateMarginLeft);
  }, []);

  useEffect(() => {
    const handleScroll = debounce(() => {}, 100);

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="mb-4">
      <div className="d-flex justify-content-center">
        {" "}
        <div
          className="dream-header"
          style={{
            width: isCompleted ? "96vw" : "860px",
          }}
        >
          <p className="dream-slider-title">
            {users[0].dreamId ? "Dream Makers:" : "Wing Makers:"}
          </p>
          <div className="view-all-button-container">
            {dreams?.length >= 12 && (
              <div
                onClick={() =>
                  profileId ? handleShowModal() : navigate("/login")
                }
                className="view-all-button"
              >
                <p className="view-all">view all</p>
              </div>
            )}
            {!isMobile ? (
              <div className="carousel-arrow-container">
                <div
                  className="icon-container-hover-right cursor-pointer"
                  onClick={() =>
                    scrollCarousel("left", "dream-dreams-container")
                  }
                >
                  <ArrowLeft className="img-arrow-hover" />
                </div>
                <div
                  className="icon-container-hover-left cursor-pointer"
                  onClick={() =>
                    scrollCarousel("right", "dream-dreams-container")
                  }
                >
                  <ArrowRight className="img-arrow-hover" />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {profileId ? (
          <InfiniteViewAllModal
            id={id}
            show={showModal}
            handleClose={handleCloseModal}
            isFulfilled={isFulfilled}
            type={SLIDERS_ENUM.WING_PAGE}
          />
        ) : (
          <></>
        )}
      </div>
      <div>
        {isMobile ? (
          <Carousel
            swipeable
            className="carousel-width slider-carousel"
            sliderClass="d-flex align-items-center justify-content-between"
            itemClass="dream-card-mobile"
            responsive={responsive}
            arrows={false}
          >
            {dreams
              ?.slice(0, 12)
              .map((dream) =>
                dream.role === "ANGEL" ? (
                  <AngelsFulfilledCard angel={dream} key={dream?.id} />
                ) : (
                  <UsersFulfilledCard dream={dream} key={dream?.id} />
                )
              )}
          </Carousel>
        ) : (
          <div
            ref={containerRef}
            className="dream-dreams-container"
            style={{
              paddingLeft: isCompleted ? "0" : `${paddingLeft}%`,
              width: "99%",
              marginLeft: "5px",
            }}
          >
            {dreams
              ?.slice(0, 12)
              .map((dream) =>
                dream.role === "ANGEL" ? (
                  <AngelsFulfilledCard angel={dream} key={dream?.id} />
                ) : (
                  <UsersFulfilledCard dream={dream} key={dream?.id} />
                )
              )}
          </div>
        )}
      </div>
    </div>
  );
}
