import React from "react";

const DreamerOrAngelSection = () => {
  return (
    <div className="small-white-container d-flex flex-column align-items-center ">
      <div className="section-container-video gap-4">
        <h2 className="dream-angel-title">Who Do You Want To Be?</h2>
        <div className="dream-angel-container">
          <div className="gradient-container w-100">
            <div
              className="padding-gradient-card d-flex flex-column align-items-center justify-content-center
               gap-2"
            >
              <div className="gray-bg">
                <span className="card-title">Dreamer</span>
              </div>
              <span className="fs-5 text-center text-black">
                a person who receives donations for their dream and only has to
                donate half as much to fulfill it.
              </span>
            </div>
          </div>
          <div>
            <span className="text-black">or</span>
          </div>
          <div className="gradient-container align-items-center text-center gap-2 w-100">
            <div
              className="padding-gradient-card d-flex flex-column align-items-center justify-content-center
               gap-2"
            >
              <div className="gr-bg">
                <span className="card-title">Dream Angel</span>
              </div>
              <span className="fs-5 text-black">
                a person who only donates, but accumulates dream points for any
                future dream, as well as gratitude and admiration from thousands
                of people.
              </span>
            </div>
          </div>
        </div>
        <span className="dream-angel-saying">The change starts with you!</span>
      </div>
    </div>
  );
};

export default DreamerOrAngelSection;
