import { Navigate, useNavigate } from "react-router-dom";
import UserProfile from "../pages/Profile/UserProfile";
import UserProfileNew from "../pages/Profile/UserProfileNew";
import Dashboard from "../pages/Dashboard/Dashboard";
import UserDataEdit from "../pages/Profile/UserDataEdit";
import PublicProfilePage from "../pages/Profile/PublicProfilePage";
import PublicProfilePageNew from "../pages/Profile/PublicProfilePageNew";
import React, { useEffect } from "react";
import Pricing from "../pages/Pricing/Pricing";
import CreateDream from "../pages/Profile/CreateDream";
import EditDream from "../pages/Profile/EditDream";
import FAQ from "../pages/Public/About/FAQ";
import AboutUs from "../pages/Public/About/AbousUs";
import HowItWorks from "../pages/Public/About/HowItWorks";

import Login from "../pages/Authorization/Login";
import ForgotPassword from "../pages/Authorization/ForgotPassword";
import ResetPassword from "../pages/Authorization/ResetPassword";
import ConfirmEmail from "../pages/Authorization/ConfirmEmail";
import TermsAndConditions from "../pages/Public/About/TermsAndConditions";
import PrivacyPolicy from "../pages/Public/About/PrivacyPolicy";
import LandingPage from "../pages/Authorization/LandingPage";
import NewsFeed from "../pages/NewsFeed/NewsFeed";
import SharedDreamPage from "../pages/SharedPages/SharedDreamPage";
import SharedDreamAngelPage from "../pages/SharedPages/SharedDreamAngelPage";
import SharedWingDreamPage from "../pages/SharedPages/SharedWingDreamPage";
import { useDispatch, useSelector } from "react-redux";
import {
  setBgLoader,
  setErrorMessage,
  setIsAuth,
  setUser,
} from "../store/users";
import { getUser } from "../services/api";
import Register from "src/pages/Authorization/Register";
import { UsersModule } from "src/types/redux";
import WingDonationPage from "src/pages/DetailsPages/WingDonationPage";
import DreamAngelPage from "src/pages/DetailsPages/DreamAngelPage";

const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = !!localStorage.getItem("accessToken");
  const user = useSelector((state: UsersModule) => state.usersModule).user;

  useEffect(() => {
    dispatch(setIsAuth(isAuth));
    if (isAuth && !user.id) {
      dispatch(setBgLoader(true));
      getUser().then((response) => {
        dispatch(setErrorMessage(""));
        dispatch(setUser(response?.data));
      });
    }
  }, []);

  useEffect(() => {
    if (
      !!user.id &&
      !user?.firstName &&
      window.location.pathname !== "/user-profile/edit-form"
    ) {
      return navigate("/user-profile/edit-form");
    }
  }, [window.location.pathname]);

  if (!!user.id) {
    return children;
  }
  if (!user.id && !isAuth) {
    return <Navigate to="/" />;
  }
  return <div>{children}</div>;
};

export const navRoutes = [
  {
    path: "/dashboard",
    component: () => (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
    exact: true,
  },
  {
    path: "/newsfeed",
    component: () => (
      <RequireAuth>
        <NewsFeed />
      </RequireAuth>
    ),
    exact: true,
  },
  // {
  //   path: "/user-profile",
  //   component: () => (
  //     <RequireAuth>
  //       <UserProfile />
  //     </RequireAuth>
  //   ),
  //   exact: true,
  // },
  {
    path: "/user-profile",
    component: () => (
      <RequireAuth>
        <UserProfileNew />
      </RequireAuth>
    ),
    exact: true,
  },
  {
    path: "/user-profile/edit-form",
    component: () => (
      <RequireAuth>
        <UserDataEdit />
      </RequireAuth>
    ),
    exact: true,
  },
  {
    path: "/user-profile/edit-dream",
    component: () => (
      <RequireAuth>
        <EditDream />
      </RequireAuth>
    ),
    exact: true,
  },
  {
    path: "/user-profile/create-dream",
    component: () => (
      <RequireAuth>
        <CreateDream />
      </RequireAuth>
    ),
    exact: true,
  },
  // {
  //   path: "/dashboard/public-page/:id/new",
  //   component: () => (
  //     <RequireAuth>
  //       <PublicProfilePage />
  //     </RequireAuth>
  //   ),
  //   exact: true,
  // },
  {
    path: "/dashboard/public-page/:id",
    component: () => (
      <RequireAuth>
        <PublicProfilePageNew />
      </RequireAuth>
    ),
    exact: true,
  },
  {
    path: "/pricing-page",
    component: () => (
      <RequireAuth>
        <Pricing />
      </RequireAuth>
    ),
    exact: true,
  },
  {
    path: "/wing-donation/:id",
    component: () => (
      <RequireAuth>
        <WingDonationPage />
      </RequireAuth>
    ),
    exact: true,
  },
  {
    path: "/angel/:id",
    component: () => (
      <RequireAuth>
        <DreamAngelPage />
      </RequireAuth>
    ),
    exact: true,
  },
];
export const nonNavRoutes = [
  {
    path: "/",
    component: <LandingPage />,
    exact: true,
  },
  { path: "/login", component: <Login />, exact: true },
  { path: "/register", component: <Register />, exact: true },
  { path: "/forgot-password", component: <ForgotPassword />, exact: true },
  { path: "/reset-password", component: <ResetPassword />, exact: true },
  { path: "/dream/:dreamId", component: <SharedDreamPage />, exact: true },
  { path: "/wing/:wingId", component: <SharedWingDreamPage />, exact: true },
  {
    path: "/dream-angel/:userId",
    component: <SharedDreamAngelPage />,
    exact: true,
  },
  { path: "/confirm-email", component: <ConfirmEmail />, exact: true },
  {
    path: "/terms-conditions",
    component: <TermsAndConditions />,
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />,
    exact: true,
  },
  { path: "/about-us", component: <AboutUs />, exact: true },
  { path: "/how-it-works", component: <HowItWorks />, exact: true },
  { path: "/faq", component: <FAQ />, exact: true },
  { path: "/*", component: <Navigate to="/newsfeed" /> },
];
