import defaultImage from "../../assets/images/defaultUser.png";
import moment from "moment";
import ShareButton from "../Buttons/ShareButton";
import React from "react";
import { useNavigate } from "react-router-dom";

const DreamAngelPost = ({ post }) => {
  const navigate = useNavigate();

  return (
    <div className="padding-10-angel">
      <div className="d-flex align-items-center flex-row justify-content-between gap-2">
        <span className="time-ago">
          {moment(post?.createdAt)?.fromNow(true)} ago
        </span>
        <div
          className="buttons-container"
          style={{ width: "72px" }}
          onClick={() => navigate("/login")}
        >
          <ShareButton width="72px" />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center gap-2 w-100 gap-card">
        <div className="gradient-border-container">
          <img
            src={post?.user?.mainImageUrl || defaultImage}
            alt="profile"
            className="angel-dream-post-avatar"
          />
        </div>
        <div className="gradient-bg">
          <span className="text-dark">Dream Angel</span>
        </div>
        <span
          className="user-name cursor-pointer"
          onClick={() => navigate("/login")}
        >
          {`${post?.user?.firstName || "No name"} 
                  ${post?.user?.lastName || ""}`}
        </span>
        <span className="time-ago">{post?.title}</span>
      </div>
    </div>
  );
};
export default DreamAngelPost;
