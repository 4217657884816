import "./assets/scss/socialv.scss";
import React, { useEffect } from "react";
import AppRouter from "./router/AppRouter";
import Loader from "./components/Partials/Loader";
import { useSelector } from "react-redux";
import { Flip, ToastContainer } from "react-toastify";
import Footer from "./components/Layout/Footer";
import { UsersModule } from "./types/redux";

const nonFooterPages = ["/dashboard", "/newsfeed"];
function App() {
  let firstClientY;
  let firstClientX;
  let clientX;
  let clientY;
  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      if (e.cancelable) {
        e.preventDefault();
        e.returnValue = false;
      }
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchmove", preventTouch, { passive: false });
  });

  useEffect(() => () => {
    const eventOptions = { passive: false } as EventListenerOptions;
    window.removeEventListener("touchstart", touchStart);
    window.removeEventListener("touchmove", preventTouch, eventOptions);
  });
  const loader = useSelector(
    (state: UsersModule) => state.usersModule
  ).bgLoader;
  return (
    <div
      style={{
        backgroundColor: "#F1F1F1",
        minHeight: "100vh",
      }}
    >
      <ToastContainer
        position="bottom-right"
        transition={Flip}
        draggablePercent={60}
        hideProgressBar={true}
        closeButton={false}
        toastClassName="shadow-none fw-bold text-black rounded-3 shadow-notifications"
        limit={1}
      />
      {loader && <Loader />}
      <AppRouter />
      {!nonFooterPages.includes(window.location.pathname) && <Footer />}
    </div>
  );
}

export default App;
