import React from "react";
import DreamCard from "./DreamCard";
import { Modal } from "react-bootstrap";
import BirthdayCard from "./BirthdayCard";
import { SLIDERS_ENUM } from "../../consts/slidersEnum";
import { useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";
import DonorsCard from "./DonorsCard";
import NewDreamCard from "./NewDreamCard";
import AngelsFulfilledCard from "../PublicandInternShared/AngelsFulfilledCard";
import UsersFulfilledCard from "../PublicandInternShared/UsersFulfilledCard";

const ViewAll = ({ show, handleClose, items, type, isFulfilled }) => {
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;

  return (
    <Modal
      backdrop={true}
      centered
      show={show}
      onHide={handleClose}
      animation={true}
      contentClassName="border-0"
      dialogClassName="modal-xlg"
      style={{ borderRadius: "8px" }}
    >
      <div>
        <div className="view-all-header">
          <h4 className="dream-slider-title">{type}</h4>
          <img
            onClick={handleClose}
            src="/interact/close.svg"
            alt="close button"
            style={{ cursor: "pointer", position: "absolute", right: "16px" }}
          />
        </div>
        <div className="items-container">
          {items &&
            type === SLIDERS_ENUM.POPULAR_DREAM &&
            items
              .slice(0, 40)
              .map((dream) => (
                <DreamCard
                  key={`${SLIDERS_ENUM.POPULAR_DREAM}-${dream?.id}`}
                  isFulfilled={isFulfilled}
                  dream={dream}
                />
              ))}
          {items &&
            type === SLIDERS_ENUM.TODAYS_BIRTHDAY &&
            items.slice(0, 40).map((dream) => (
              <div
                key={`${SLIDERS_ENUM.TODAYS_BIRTHDAY}-${dream?.id}`}
                className="view-all-birthday-modal d-flex align-items-center"
              >
                <BirthdayCard dream={dream} isFulfilled={isFulfilled} />
              </div>
            ))}
          {items &&
            type === SLIDERS_ENUM.TOP_DONORS &&
            items.slice(0, 40).map((donations) => (
              <div
                key={`${SLIDERS_ENUM.TOP_DONORS}-${donations?.id}`}
                className="view-all-birthday-modal d-flex align-items-center"
              >
                <DonorsCard
                  donations={donations}
                  isFulfilled={isFulfilled}
                  allFulfilled={allFulfilled}
                />
              </div>
            ))}
          {items &&
            type === SLIDERS_ENUM.NEW_DREAMS &&
            items.slice(0, 40).map((donations) => (
              <div
                key={`${SLIDERS_ENUM.NEW_DREAMS}-${donations?.id}`}
                className="view-all-birthday-modal d-flex align-items-center"
              >
                <NewDreamCard
                  dream={donations}
                  isFulfilled={isFulfilled}
                  allFulfilled={allFulfilled}
                />
              </div>
            ))}
          {items &&
            type === SLIDERS_ENUM.WING_PAGE &&
            items.slice(0, 40).map((dream) => (
              <div
                key={`${SLIDERS_ENUM.WING_PAGE}-${dream?.id}`}
                className="view-all-birthday-modal d-flex align-items-center"
              >
                {dream.role === "ANGEL" ? (
                  <AngelsFulfilledCard angel={dream} />
                ) : (
                  <UsersFulfilledCard dream={dream} />
                )}
              </div>
            ))}
          {items &&
            type === SLIDERS_ENUM.ANGEL_PAGE &&
            items
              .slice(0, 40)
              .map((dream) => (
                <DreamCard
                  key={`${SLIDERS_ENUM.ANGEL_PAGE}-${dream?.id}`}
                  dream={dream}
                  isFulfilled={isFulfilled}
                />
              ))}
        </div>
      </div>
    </Modal>
  );
};

export default ViewAll;
