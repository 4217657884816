import { facebook, instagram, linkedin, youtube } from "../../consts/SvgIcon";
import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer-container d-flex flex-column gap-4">
        <div className="d-flex align-items-center justify-content-around flex-wrap gap-4 column-mobile">
          <div className="logo-footer" />
          <div className="d-flex cursor-pointer align-items-center gap-4 column-mobile">
            <span
              className="st-text lh-1"
              onClick={() => navigate("/how-it-works")}
            >
              How it works
            </span>
            <span
              className="st-text lh-1"
              onClick={() => navigate("/about-us")}
            >
              About us
            </span>
            <span
              className="st-text"
              onClick={() => navigate("/terms-conditions")}
            >
              Terms
            </span>
            <span
              className="st-text"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy
            </span>
            <span className="st-text" onClick={() => navigate("/faq")}>
              FAQ
            </span>
          </div>
          <div className="social-links-container">
            <span className="follow-us">Follow us:</span>
            <div className="d-flex align-items-center links-container">
              <div
                className="cursor-pointer"
                onClick={() =>
                  (window.location.href =
                    "https://www.youtube.com/@dreamerz_net/featured")
                }
              >
                {youtube()}
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  (window.location.href = "https://m.facebook.com/dreamerz.net")
                }
              >
                {facebook()}
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  (window.location.href =
                    "https://www.instagram.com/dreamerz_net/?igshid=YmMyMTA2M2Y=")
                }
              >
                {instagram()}
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  (window.location.href =
                    "https://www.linkedin.com/company/102331009/admin/page-posts/published/")
                }
              >
                {linkedin()}
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center text-center flex-column column-mobile"
          style={{ color: "#A8A6A6" }}
        >
          <span className="footer-year-text">{`© Dreamerz ${currentYear}, All rights reserved`}</span>
        </div>
      </div>
    </>
  );
};
export default Footer;
