import React, { useEffect, useState } from "react";
import Card from "../../../components/Partials/Card";
import { Carousel, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import coverImg from "../../../assets/images/coverImg.png";
import defaultImage from "../../../assets/images/defaultUser.png";
import "../../../styles/Dashboard.css";
import DreamMakersModal from "../../../components/DreamMakersModal";

import { getComments, getPublicDreamDonations } from "../../../services/api";
import { setBgLoader, setPublicReceived } from "../../../store/users";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import GradientButton from "../../../components/Buttons/GradientButton";
import ShareButton from "../../../components/Buttons/ShareButton";
import FeedComments from "../../../components/Comments/FeedComments";
import ShareModal from "../../../components/Partials/ShareModal";
import FulfilledBy from "../../../components/FulfilledBy";
import ResponsiveCommentsModal from "../../../components/Comments/ResponsiveCommentsModal";
import { SOCIALS_ENUM } from "../../../consts/socialsEnum";
import NewsfeedSocialInteraction from "../../../components/Partials/NewsfeedSocialInteraction";
import { UsersModule } from "src/types/redux";
import TooltipDetails from "src/components/Tooltip/TooltipDetails";
import { generateTooltipKey } from "../../../utils/gerenateTooltipKey";
import { fulfillCheck } from "src/utils/fulfillCheck";
import { updateDreamLikes } from "src/store/action";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";
import { shareTo } from "src/utils/share";
import { commentType } from "src/consts/commentsTypeEnum";

type DreamPostType = {
  post;
  isFulfilled: (id: string) => void;
  onClick: (id: string) => void;
};

const Dream = ({ post, isFulfilled, onClick }: DreamPostType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [shareModal, setShareModal] = useState(false);
  const [modalProps, setModalProps] = useState(false);
  const [show, setShow] = useState(false);
  const [comments, setComments] = useState([]);
  const [hasMore, setHasMore] = useState(Boolean);
  const [link, setLink] = useState(process.env.REACT_APP_BASE_URL);
  const [commentInput, setCommentInput] = useState("");

  const mainUser = useSelector((state: UsersModule) => state.usersModule).user;
  const mainImg = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  const allFulfilled = useSelector(
    (state: UsersModule) => state.usersModule
  ).allProfileFulfilled;
  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;

  useEffect(() => {
    const likesCount = post.newsFeedDream.likedDreamsByUsers.length;
    const likedBy = post.newsFeedDream.likedDreamsByUsers.map(
      (user) => user.id
    );
    const userLiked = likedBy.includes(localStorage.getItem("profileId"));
    dispatch(
      updateDreamLikes(post.newsFeedDream.id, likesCount, userLiked, likedBy)
    );
  }, [post, dispatch]);

  const progress = calculateDreamProgress(post?.newsFeedDream);
  const tooltipKey = generateTooltipKey(post?.user?.id);
  const isDreamFulfilled = fulfillCheck(
    allFulfilled,
    post.newsFeedDream,
    post?.user?.id
  );

  const openCommentModal = () => {
    dispatch(setBgLoader(true));
    setModalProps(true);
    getComments(post?.newsFeedDream?.id, 1, 10).then((res) => {
      setComments(res?.data?.results);
      setHasMore(res?.data?.meta?.hasNextPage);
      setModalProps(true);
    });
  };

  const progressClick = () => {
    dispatch(setPublicReceived({}));
    setShow((prev) => !prev);
    getPublicDreamDonations(post?.newsFeedDream?.id, 1).then((res) => {
      dispatch(setPublicReceived(res?.data));
    });
  };
  const carouselItem = (img, dream) => {
    return (
      <Carousel.Item key={img} onClick={openCommentModal}>
        <img src={img} className="post-image cursor-pointer" alt="img" />
      </Carousel.Item>
    );
  };
  const share = () => {
    setLink(`${process.env.REACT_APP_BASE_URL}/dream/${post?.dreamId}`);
    shareTo({
      link: `${link}/dream/${post?.dreamId}`,
      type: post?.type,
      dreamId: post?.dreamId,
      replyUserId: mainUser?.id,
      setShareModal,
    });
  };

  return (
    <div className="w-100">
      <Card className="rounded-3 gap-card pb-0 shadow m-0">
        <Card.Body className="p-0 gap-post">
          <ShareModal
            customModals={shareModal}
            setCustomModal={setShareModal}
            sharedLink={link}
            post={post?.newsFeedDream}
            mainUser={mainUser}
          />
          {post?.type === "FULFILL_DONATION" && (
            <FulfilledBy
              post={post}
              contributor={post?.contributor}
              text={post?.title}
              onClick={onClick}
              isFulfilled={isFulfilled}
            />
          )}
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
            <div
              className="d-flex align-items-center gap-2 cursor-pointer user-image"
              onClick={() => {
                onClick(post?.id);
                navigate(`/dashboard/public-page/${post?.user?.id}`);
              }}
              data-tooltip-id={tooltipKey}
            >
              <div>
                <img
                  src={post?.user?.mainImageUrl || defaultImage}
                  alt="profile-img"
                  className="avatar"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div className="d-flex flex-column ">
                <span id="username" className="user-name">
                  {`${post?.user?.firstName || "No name"} 
                        ${post.user?.lastName || ""}`}
                </span>
                <span className="time-ago">
                  {moment(post?.newsFeedDream?.createdAt)?.fromNow(true)} ago
                </span>
              </div>
            </div>
            <TooltipDetails
              userId={post?.user?.id}
              isFulfilled={isFulfilled}
              allFulfilled={allFulfilled}
              tooltipKey={tooltipKey}
            />
            <div className="buttons-container">
              <ShareButton width="72px" onClick={share} />
              <GradientButton
                minWidth="137px"
                text={isDreamFulfilled ? "View Profile" : "Fulfill"}
                onClick={() =>
                  isDreamFulfilled
                    ? navigate(`/dashboard/public-page/${post?.user?.id}`)
                    : isFulfilled(post?.newsFeedDream?.id)
                }
              />
            </div>
          </div>
          <Carousel
            indicators={post?.newsFeedDream?.images?.length > 1}
            interval={null}
            controls={
              post?.newsFeedDream?.images?.length > 1 && window.innerWidth > 500
            }
          >
            {post?.newsFeedDream.images?.length
              ? post?.newsFeedDream.images?.map((img) =>
                  carouselItem(img?.url, post?.newsFeedDream)
                )
              : carouselItem(coverImg, post?.newsFeedDream)}
          </Carousel>
          <span className="my-span lh-sm text-dark">
            {post?.newsFeedDream?.title || "No Title"}
          </span>
          <div>
            <div className="d-flex align-items-center justify-content-between gap-2">
              <ProgressBar
                onClick={() => progressClick()}
                now={progress}
                variant={"gradient"}
                className="black-hover post-progress-bar"
              />
              <span className="fw-bold text-dark" style={{ fontSize: "12px" }}>
                {isNaN(progress) ? 0 : progress}%
              </span>
            </div>
            <div className="d-flex justify-content-between text-dark">
              <span>
                Fulfilled <b>{post?.newsFeedDream?.donations || 0}</b>
              </span>
              <span>
                Received <b>{post?.newsFeedDream?.amountReceived || 0}</b>
              </span>
            </div>
            <NewsfeedSocialInteraction
              dream={post.newsFeedDream}
              openCommentModal={openCommentModal}
              type={SOCIALS_ENUM.NEWSFEED_DREAM}
            ></NewsfeedSocialInteraction>
            <FeedComments
              postId={post?.id}
              user={mainUser}
              dreamId={post?.newsFeedDream?.id}
              mainImg={mainImg}
              dream={post?.newsFeedDream}
              openCommentModal={openCommentModal}
              isFulfilled={isFulfilled}
              type={commentType.dream}
              commentInput={commentInput}
              setCommentInput={setCommentInput}
            />
            <ResponsiveCommentsModal
              dream={post?.newsFeedDream}
              modalProps={modalProps}
              setModalProps={setModalProps}
              setComments={setComments}
              comments={comments}
              setHasMore={setHasMore}
              hasMore={hasMore}
              mainImage={mainImg}
              user={post?.user}
              isFulfilled={isFulfilled}
              currentUser={mainUser}
              allFulfilled={allFulfilled}
              progress={progress}
              shareTo={shareTo}
              post={post}
              type={commentType.dream}
              currentUserComment={commentInput}
              setComment={setCommentInput}
            />
          </div>
          {publicReceived?.results?.length ? (
            <DreamMakersModal
              donations={publicReceived}
              show={show}
              setShow={setShow}
              dreamId={post?.newsFeedDream?.id}
              card={true}
            />
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Dream;
