import { Modal } from "react-bootstrap";
import React from "react";
import GradientButton from "../Buttons/GradientButton";
import { link, viber } from "../../consts/SvgIcon";
import { shareNotification } from "../../services/api";
import { socialMedia } from "../../consts/items";

const ShareModal = ({
  customModals,
  setCustomModal,
  sharedLink,
  post,
  mainUser,
  anotherModalOpen = false,
}) => {
  const shareOn = (url, media) => {
    navigator.clipboard.writeText(`${sharedLink}`).then(() => {
      const navUrl = url + sharedLink;
      window.open(navUrl, "_blank");
    });
    !post?.charityId &&
      shareNotification({
        type: post?.type || "DREAM",
        dreamId: post?.dreamId,
        replyUserId: mainUser?.id,
        media: media,
        dreamAngelId: post?.userId,
      });
  };

  return (
    <Modal
      backdrop={true}
      centered
      show={customModals}
      onHide={() => setCustomModal(false)}
      animation={true}
      style={{ borderRadius: "8px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark fw-bold">Share</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3 text-dark">
        {anotherModalOpen && 
        <div className="modal-bg-opacity">
          <div className="modal-bg-opacity-color"></div>
        </div>}
        <span>via social media:</span>
        <div className="d-flex gap-2 cursor-pointer">
          {socialMedia?.map(({ media, icon, id, url }) => (
            <div id={id} onClick={() => shareOn(url, media)} key={id}>
              {icon}
            </div>
          ))}
          <a
            id="viber"
            href={`viber://pa?=text=${link}`}
            onClick={() => navigator.clipboard.writeText(`${sharedLink}`)}
          >
            {viber()}
          </a>
        </div>
        <span>or copy link</span>
        <div className="share-container">
          <div style={{ minWidth: "16px" }}>{link()}</div>
          <span className="my-title">{sharedLink}</span>
          <GradientButton
            minWidth={"96px"}
            text="Copy"
            onClick={() => navigator.clipboard.writeText(`${sharedLink}`)}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ShareModal;
