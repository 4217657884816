import defaultImage from "../../assets/images/defaultUser.png";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UsersModule } from "src/types/redux";
import { DreamDto } from "src/services/api/data-contracts";
import "../../styles/comments-line.css";

const Commented = ({ el, setShow }) => {
  const navigate = useNavigate();
  const dream = useSelector((state: UsersModule) => state.usersModule)
    .dream as DreamDto;

  return (
    <div
      className="notification-item-container"
      onClick={() => {
        el?.dreamId
          ? el?.dreamId === dream?.id
            ? navigate(`/user-profile`)
            : navigate(`/dream-page/${el?.dreamId}`)
          : navigate(`/wing/${el?.charityId}`);
        setShow(false);
      }}
    >
      <div className="d-flex gap-2">
        <div
          className={`${
            el?.sender?.role === "ANGEL" ? "gradient-border-notification" : ""
          }`}
          style={{ borderRadius: el?.sender?.role === "ANGEL" ? "100px" : "" }}
        >
          <img
            src={el?.sender?.mainImageUrl || defaultImage}
            alt="profile"
            className="avatar avatar-notification"
          />
        </div>
        <div className="d-flex flex-column ">
          <div className="div">
            <div>
              <span className="fs-5 fw-bold text-dark underline-name cursor-pointer mx-left-1 notification-comment-username">
                {el?.sender?.firstName} {el?.sender?.lastName}
              </span>
              {/* {el?.sender?.role === "ANGEL" ? (
                  <div className="tooltip-angel-badge comment-badge">
                    Dream Angel
                  </div>
                ) : (
                  ""
                )} */}
              <span className="fs-5" style={{ color: "#A8A6A6" }}>
                {el?.title}
              </span>
            </div>
          </div>
          <span className="text-dark">
            {moment(`${el?.createdAt}`)?.fromNow(true)} ago
          </span>
        </div>
      </div>
      {!el?.seen && <div className="notify-circle-card" />}
    </div>
  );
};
export default Commented;
