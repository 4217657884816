export const fulfillCheck = (allFulfilled, dream, userId) => {
  const mainUserId = localStorage.getItem("profileId");

  if (!mainUserId) {
    return true;
  }

  return allFulfilled?.find(
    (d) =>
      d === dream?.id ||
      dream?.amount === dream?.amountReceived ||
      mainUserId === userId
  );
};
