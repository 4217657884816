export const reminderTypes = {
  SUBSCRIBE_REMINDER: "pricing-page",
  DONATION_DREAM_REMINDER: "dashboard",
  ADMIN_NOTIFICATION: "user-profile",
};

export const wingTypes = {
  POST_WING_DONATION: "wings-page",
  WING_DONATION_FULFILLED: "wings-page",
};
export const commentedTypes = { POST_COMMENT: "", REPLY_COMMENT: "" };
export const othersTypes = {
  POST_DREAM: "dashboard/public-page",
  DREAM_FULFILLED: "dashboard/public-page",
  FULFILL_DONATION: "dashboard/public-page",
  SHARE_DREAM: "dashboard/public-page",
};
