import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Carousel, ProgressBar } from "react-bootstrap";
import defaultImage from "../../../assets/images/defaultUser.png";
import { setPublicReceived } from "../../../store/users";
import { useDispatch, useSelector } from "react-redux";
import { getPublicDreamDonations } from "../../../services/api";
import DreamMakersModal from "../../DreamMakersModal";
import "../../../styles/BirthdaySlider.css";
import coverImg from "../../../assets/images/coverImg.png";
import { UsersModule } from "../../../types/redux";
import React from "react";
import TooltipDetails from "src/components/Tooltip/TooltipDetails";
import { generateTooltipKey } from "src/utils/gerenateTooltipKey";
import { fulfillCheck } from "src/utils/fulfillCheck";
import { calculateDreamProgress } from "src/utils/calculateDreamProgress";

const DreamerReceivedCard = ({ received, isFulfilled, allFulfilled }) => {
  const progress = calculateDreamProgress(received);
  const isDreamFulfilled = fulfillCheck(
    allFulfilled,
    received,
    received?.user?.id
  );
  const tooltipKey = generateTooltipKey(received?.user?.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const publicReceived = useSelector(
    (state: UsersModule) => state.usersModule
  ).publicReceived;

  const progressClick = () => {
    dispatch(setPublicReceived({}));
    setShow((prev) => !prev);
    getPublicDreamDonations(received?.id, 1).then((res) => {
      dispatch(setPublicReceived(res?.data));
    });
  };

  const carouselItem = (img) => {
    return (
      <Carousel.Item key={img}>
        <img src={img} className="profile-received-card-header" alt="img" />
      </Carousel.Item>
    );
  };

  return (
    <div className="profile-received-card  shadow-card rounded-3">
      <div className="profile-received-card-header">
        <Carousel
          indicators={false}
          interval={null}
          controls={received?.images?.length > 1}
        >
          {received.images?.length
            ? received.images?.map((img) => carouselItem(img?.avatarUrl))
            : carouselItem(coverImg)}
        </Carousel>
      </div>
      <Link
        to={`/dashboard/public-page/${received?.user?.id}`}
        style={{ height: "0px", zIndex: "10" }}
      >
        <div className="bg-white border-radius-img profile-received-img-mg">
          <img
            src={received?.user?.mainImageUrl || defaultImage}
            alt="profile-img"
            className="bg-white profile-received-avatar"
          />
        </div>
      </Link>
      <div className="profile-received-main-box">
        <div style={{ textAlign: "center" }}>
          <Link to={`/dashboard/public-page/${received?.user?.id}`}>
            <span
              className="profile-received-username-box my-name-span name-overflow-cards text-underline"
              data-tooltip-id={tooltipKey}
            >
              {`${received?.user?.firstName || "No Name"} ${
                received.user?.lastName || ""
              }`}
            </span>
          </Link>
          <TooltipDetails
            userId={received?.user?.id}
            isFulfilled={isFulfilled}
            allFulfilled={allFulfilled}
            tooltipKey={tooltipKey}
          />
          <div
            className="text-dark cursor-pointer birthday-dream-box"
            style={{ width: "-webkit-fill-available" }}
          >
            <span className="my-span lh-sm dream-description">
              {received?.title || received.user?.dreams?.[0]?.title}
            </span>
          </div>
        </div>

        <div className="progress-box">
          <div className="scope-container d-flex align-items-center justify-content-between">
            <p className="dream-scope">Scope</p>
            <p className="birthday-dream-percentage">
              {isNaN(progress) ? 0 : progress}%
            </p>
          </div>
          <div className="dream-progress-container">
            <ProgressBar
              onClick={() => progressClick()}
              style={{ width: "100%", height: "14px", cursor: "pointer" }}
              now={progress}
              variant={"gradient"}
              className={"black-hover"}
            />
          </div>
        </div>
        {publicReceived?.results?.length > 0 && (
          <DreamMakersModal
            donations={publicReceived}
            show={show}
            setShow={setShow}
            dreamId={received.id}
            card={true}
          />
        )}
        <div className="d-flex justify-content-between sliders-button">
          <div
            className="custom-gradient sliders-button"
            onClick={() =>
              isDreamFulfilled
                ? navigate(`/dashboard/public-page/${received?.user?.id}`)
                : isFulfilled(received?.id)
            }
          >
            <div className="dashboard-card-buttons-text">
              <span>
                {isDreamFulfilled
                  ? "View Profile"
                  : `Fulfill ${
                      received?.user?.gender === "FEMALE" ? "Her" : "His"
                    } Dream`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamerReceivedCard;
